import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./sba-approved-lenders.css"
import { Link } from "gatsby"

const InternationalBusinessAndNonResident = () => {
  return (
    <Layout>
      <SEO title="International Business And Non Resident" />
      <div className="container primary-bg">
        <h1 class="small-padding">
          International Business And Non-Resident Resources
        </h1>
        <p className="small-padding">
          Miami's business community is one of the most diverse in the country. As such we want to make sure foreign-owned companies have access to the resources that best align to their unique needs. Below please find a list of active bi-national chambers of commerce, and trade and investment promotion agencies located in Miami-Dade; these partners are best equipped to address the impact on international businesses and the resources available for non-residents. 
        </p>
        <hr />
        <Link className="full-cta" to="/specialized-services">
          Specialized Services
        </Link>
        <Link className="full-cta" to="/immigration-attorneys">
          Immigration Attorneys
        </Link>
        <hr />
      </div>
    </Layout>
  )
}

export default InternationalBusinessAndNonResident
